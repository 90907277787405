import React from "react"
import { graphql, Link } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Img from "gatsby-image"

import Body from '../layout/blogpost/main'


const shortcodes = { Link } // Provide common components here

export default function PageTemplate({ data: { mdx } }) {
  let headerImg = mdx.frontmatter.headerImg.childImageSharp.fluid

  return (
    <Body>
      <div>
        <h1>{mdx.frontmatter.title}</h1>
        <Img fluid={headerImg} />
        <p>{mdx.frontmatter.author} {mdx.frontmatter.dateWritten}</p>
        <hr />
        <br />
        <MDXProvider components={shortcodes}>
          <MDXRenderer>
            {mdx.body}
          </MDXRenderer>
        </MDXProvider>
      </div>
      <br />
      <br />
      <hr />
    </Body>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id      
      body
      frontmatter {
        title
        author
        dateWritten
        headerImg {
          childImageSharp {
              fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
              }
          }
      }
      }
    }
  }
`